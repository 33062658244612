.subpage.contact-us .banner {
  max-height: 100px;

  @media (min-width: $large) {
    max-height: 145px;
  }
}

.contact-container {
  position: relative;
  left: -25px;
  display: flex;
  width: 100vw;
  background: #ffffff;
  flex-flow: column-reverse;

  @media (min-width: $large) {
    left: 0;
    margin: 0 auto;
    max-width: 1064px;
    flex-flow: row;
  }

  .contact-info {
    padding: 40px 0 40px 25px;
    width: 100%;
    border-right: 1px solid $alto;
    background: rgba(255, 255, 255, 0.9);

    @media (min-width: $large) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 0 60px 0 115px;
      width: 36%;
      background: transparent;
    }

    h2 {
      position: relative;
      margin-bottom: 30px;
      font-size: 30px;
      font-family: $lexiaadvert;
    }

    h3 {
      position: relative;
      margin-bottom: 15px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 800;
      font-family: $proxima;

      &:before {
        @media (min-width: $large) {
          position: absolute;
          top: 0;
          left: -50px;
          width: 34px;
          height: 14px;
          background: transparent url('../images/short-blue-diagonal.png') no-repeat center center;
          content: '';
        }
      }
    }

    ul {
      margin-left: 0;
      padding-left: 20px;

      li {
        margin: 20px 0;
      }
    }

    .diagonal-border-thick {
      background: transparent;

      &:after {
        content: none;
      }
    }
  }

  .contact-content {
    padding: 40px 0 40px 25px;
    width: 100%;

    @media (min-width: $large) {
      margin-top: 45px;
      margin-bottom: 75px;
      padding: 0 60px 0 115px;
      width: 63%;
      background: #ffffff;
    }
  }
}
