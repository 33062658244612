.subpage .main {
  .tabs {
    margin: 0 0 50px 0;

    .tab-list {
      display: none;
      margin: 0 0 10px 0;
      padding: 0;
      list-style: none;

      &[role='tablist'] {
        display: block;
      }

      li {
        display: inline-block;
        margin: 0 2px 0 0;
        padding: 0;
        white-space: nowrap;

        a {
          display: block;
          padding: 15px 18px;
          border: 2px solid $mercury;
          background: $concrete;
          color: $tundora;
          font-weight: 600;
          font-size: 14px;
          font-family: $lexia;

          &:hover {
            border: 2px solid $atoll;
            background: $bluechill;
            color: #ffffff;
            text-decoration: none;
          }

          &:focus {
            text-decoration: none;
          }

          &[role='tab']:focus {
            border: 2px solid $atoll;
            background: $bluechill;
            color: #ffffff;
          }

          &[aria-selected] {
            border: 2px solid $atoll;
            background: $bluechill;
            color: #ffffff;

            &:hover {
              border: 2px solid $atoll;
              background: $bluechill;
              color: #ffffff;
            }
          }
        }
      }
    }

    [role='tabpanel'] {
      margin: -12px 0 0 4px;
      padding: 15px 18px;
      border: 2px solid $mercury;
      background: $alabaster2;
      color: $tundora;
      font-size: 16px;
      font-family: $proxima;

      &[aria-hidden='true'] {
        display: none;
      }

      .tab-title {
        display: none;
      }
    }
  }
}
