.project-listing {
    margin: 0 0 0 -25px;
    width: 100vw;

    @media screen and (min-width: $large) {
        display: flex;
        margin: 0;

        flex-wrap: wrap;
    }

    .row-fill {
        display: none;

        &:nth-child(even) {
            @media screen and (min-width: $medium) {
                display: block;
                border-bottom: 1px solid #fff;
                background: $concrete url('../images/striped-background.gif') repeat top left;

                flex-grow: 1;
            }
        }
    }

    .project-nav-link {
        position: relative;
        display: block;
        overflow: hidden;
        margin-bottom: 1px;
        height: 235px;
        background: $alto2;

        &:before {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: rgba(black, 0.55);
            content: '';
        }

        &:hover:before {
            background: rgba(black, 0.75);
        }

        @media screen and (min-width: $large) {
            margin-right: 1px;
            width: calc(50% - 1px);
            height: auto;
            height: 325px;

            &:nth-child(even) {
                margin-right: 0;
                width: 50%;
            }
        }

        img {
            position: relative;
            top: 50%;
            left: 50%;
            margin: 0;
            width: 100%;
            transform: translateX(-50%) translateY(-50%);
        }
    }

    .nav-text {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        padding-bottom: 5px;
        border-bottom: 2px solid $yellowsea;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-size: 24px;
        font-family: $lexiaadvert;
        line-height: 1.25em;
        transform: translateX(-50%) translateY(-50%);
    }
}

.subpage.experience.banner-collapse .banner {
    max-height: 256px;

    @media (min-width: $large) {
        max-height: 145px;
    }
}

.project-details-container {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: $large) {
        flex-direction: row;
        margin-bottom: rem-calc(40px);
    }

    .left-column {
        margin-top: 75px;
        margin-bottom: 75px;
        width: calc(100% - 75px);

        @media (min-width: $large) {
            overflow: hidden;
            margin-top: 0;
            margin-bottom: 0;

            // padding-top: 40px;
            padding-bottom: 75px;
            width: 43%;
        }
    }

    .right-column {
        overflow: visible;
        width: 100%;

        @media (min-width: $large) {
            overflow: hidden;
            width: 57%;
        }
    }

    .background-image {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 0;
        min-width: 110%;
        min-height: 110%;
        filter: blur(15px);
        transform: translateX(-50%) translateY(-50%);
    }

    .display-image {
        position: relative;
        z-index: 1;
        border: 0;
    }

    .diagonal-border-thick {
        background: transparent;
    }

    .project-title {
        position: absolute;
        top: 100px;
        margin-left: -25px;
        padding: 40px 0 40px 25px;
        width: calc(100% + 50px);
        background: rgba(white, 0.9);

        @media (min-width: $large) {
            position: relative;
            top: auto;

            // margin-left: 115px;
            margin: 0;
            padding: 40px 0 40px 115px;
            width: auto;
            background: $concrete;

            // background: transparent;
        }

        h2 {
            position: relative;
            margin-bottom: 30px;
            font-size: 30px;
            font-family: $lexiaadvert;
        }
    }

    .overview {
        position: relative;
        margin-top: -156px;
        padding: 40px 0 0 25px;
        padding-left: 0;
        width: 100%;
        background: $concrete;
        background: transparent url('../images/blue-diagonal-horizontal.png') repeat-x top left;
        font-size: 16px;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-top: 14px solid #fff;
            border-right: 14px solid transparent;
            content: '';

            @media (min-width: $large) {
                content: none;
            }
        }

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 0;
            border-right: 2px solid #fff;
            border-bottom: 14px solid #fff;
            border-left: 14px solid transparent;
            content: '';
        }

        @media (min-width: $large) {
            margin-top: 0;
            padding: 20px 60px 0 115px;
            background: white;
        }

        h2 {
            position: relative;
            margin-bottom: 30px;
            font-size: 30px;
            font-family: $lexiaadvert;
        }

        h3 {
            // margin-top: 50px;
            position: relative;
            margin-bottom: 25px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 800;
            font-family: $proxima;

            &:before {
                content: none;

                @media (min-width: $large) {
                    position: absolute;
                    top: 0;
                    left: -50px;
                    width: 34px;
                    height: 14px;
                    background: transparent url('../images/short-blue-diagonal.png') no-repeat center center;
                    content: '';
                }
            }
        }

        ul {
            margin-left: 0;
            padding-left: 20px;

            li {
                margin: 20px 0;
            }
        }
    }

    .diagonal-border-thick {
        &:after {
            content: none;
        }
    }

    .gallery {
        margin: 0 -25px;
        width: 100vw;

        @media (min-width: $large) {
            margin: 0;
            width: 100%;
        }

        img {
            margin: 0;
            width: auto;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;

            &.no-blur {
                position: absolute;
                top: 0;
                left: 0;
                display: none;
                width: 100%;
                height: 100%;
            }
        }
    }

    .featured-image {
        position: relative;
        display: none;
        overflow: hidden;
        margin-bottom: 0;
        max-height: 700px;

        @media (min-width: $medium) {
            display: block;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 700px;
            border: 0;
        }
    }

    h3.gallery-title {
        position: absolute;
        bottom: 3px;
        z-index: 2;
        display: none;
        margin: 0;
        padding: 15px 30px;
        background: #16181c;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
        font-size: 18px;
        font-family: $proxima;

        @media (min-width: $large) {
            display: block;
        }
    }

    .thumbnail-container {
        position: relative;
        overflow: hidden;
        height: 115px;

        .overflowing .show-all-images {
            display: block;
        }

        .show-all-images {
            position: absolute;
            top: 0;
            right: 0;
            width: 111px;
            height: 115px;
            background: rgba(0, 0, 0, 0.5);
            color: $concrete;
            text-align: center;
            line-height: 115px;
            cursor: pointer;

            .fa {
                font-size: 34px;
                line-height: 115px;

                &.fa-minus {
                    display: none;
                }
            }

            .hidden-text {
                display: block;
                visibility: hidden;
            }
        }

        &.show-all {
            overflow: visible;

            .thumbnails {
                z-index: 1;

                flex-wrap: wrap;
            }

            .show-all-images {
                height: 100%;
                background: $tundora;

                .fa {
                    height: 100%;
                }
            }

            .fa.fa-plus {
                display: none;
            }

            .fa.fa-minus {
                display: block;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
            }
        }
    }

    .mobile-carousel {
        display: block;

        @media (min-width: $medium) {
            display: none;
        }
    }

    .carousel-nav {
        position: relative;
        top: -55px;

        .mobile-slider-nav {

            &.right,
            &.left {
                position: absolute;
                top: 50%;
                margin: 0;
                width: 65px;
                height: 65px;
                border-radius: 0;
                background: rgba(0, 0, 0, 0.5);
                color: #f2f2f2;
                text-align: center;
                cursor: pointer;
                transform: translateY(-50%);

                @media (min-width: $medium) {
                    display: none;
                }
            }

            &.right {
                right: 0;
            }

            &.left {
                left: 0;
            }

            .fa {
                margin: 0;
                width: 65px;
                height: 100%;
                font-size: 34px;
                line-height: 65px;
            }

            .hidden-text {
                display: block;
                visibility: hidden;
            }
        }
    }

    .thumbnails {
        position: relative;
        display: none;
        padding-right: 111px;
        width: 100%;
        background: $concrete url('../dist/images/striped-background.gif') repeat top left;

        flex-wrap: nowrap;

        @media (min-width: $medium) {
            display: flex;
        }

        span {
            position: relative;
            margin: 0 25px;

            @media (min-width: $medium) {
                margin: 0;
            }

            &:focus:after {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.25;
            }
        }

        img {
            width: auto;
            height: 115px;
        }

        .row-fill {
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;

            flex-grow: 1;
        }
    }

    .description {
        padding: 75px 0 160px;
        width: 100%;

        @media (min-width: $large) {
            padding: 75px 56px;
            border-left: 1px solid $alto;
        }

        h2 {
            margin-bottom: 14px;
            font-size: 30px;
            font-family: $lexiaadvert;
        }

        img {
            margin: 0;
        }
    }

    .services {
        position: relative;
        padding-top: 0;
        padding-left: 0;
        width: 100%;
        height: 100%;

        @media (min-width: $large) {
            padding: 0 60px 0 110px;
            background: #fff;
        }

        h3 {
            position: relative;
            margin-bottom: 25px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 800;
            font-family: $proxima;

            &:before {
                content: none;

                @media (min-width: $large) {
                    position: absolute;
                    top: 0;
                    left: -50px;
                    width: 34px;
                    height: 14px;
                    background: transparent url('../images/short-blue-diagonal.png') no-repeat center center;
                    content: '';
                }
            }
        }

        ul {
            margin-left: 0;
            padding-left: 20px;

            li {
                margin: 20px 0;
            }
        }

        p {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .call-to-action {
        position: relative;
        margin-top: 80px;
        padding: 40px 25px;
        border: 5px solid $mercury;
        background: $alabaster2;

        @media (min-width: $large) {
            padding: 45px 50px;
        }

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            border-top: 75px solid $mercury;
            border-right: 0;
            border-bottom: 0;
            border-left: 75px solid $alabaster2;
            content: '';
        }

        &:after {
            position: absolute;
            top: 5px;
            right: 8px;
            color: $nobel;
            content: '\f003';
            font-size: 24px;
            font-family: FontAwesome;
        }

        h2 {
            margin-bottom: 14px;
            font-size: 24px;
            font-family: $lexiaadvert;
        }

        p {
            margin-bottom: 20px;
        }

        .btn.btn-primary {
            width: 225px;
            font-size: 14px;
        }
    }
}

.subpage.no-blur {
    .project-details-container .gallery {
        img.has-blur {
            display: none;
        }

        img.no-blur {
            display: block;
        }
    }
}

.subpage.project-detail {
    @include large-up {
        section.footer {
            padding-top: 324px;
        }

        .project-listing-related {
            position: absolute;
            left: 0;
        }
    }
}