.two-column,
.three-column,
.four-column,
.five-column,
.six-column,
ul.two-column,
ul.three-column,
ul.four-column,
ul.five-column,
ul.six-column {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  @include medium-up {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .col,
  li.col {
    overflow: hidden;
    position: relative;
    width: 100%;
    margin-bottom: rem-calc(40px);
  }

  li.col {
    margin: 0;
    padding: 0;

    &:before {
      display: none;
    }
  }
}

.two-column .col,
ul.two-column li.col {
  @include medium-up {
    width: calc(100% / 2 - 20px);
  }
}

.three-column .col,
ul.three-column li.col {
  @include medium-up {
    width: calc(100% / 2 - 20px);
  }
  @include large-up {
    width: calc(100% / 3 - 20px);
  }
}

.four-column .col,
ul.four-column li.col {
  @include medium-up {
    width: calc(100% / 2 - 20px);
  }
  @include large-up {
    width: calc(100% / 4 - 20px);
  }
}

.five-column .col,
ul.five-column li.col {
  @include medium-up {
    width: calc(100% / 2 - 20px);
  }
  @include large-up {
    width: calc(100% / 5 - 20px);
  }
}

.six-column .col,
ul.six-column li.col {
  @include medium-up {
    width: calc(100% / 2 - 20px);
  }
  @include large-up {
    width: calc(100% / 4 - 20px);
  }
  @include xlarge-up {
    width: calc(100% / 6 - 20px);
  }
}