.accordions {
  margin-bottom: 60px;

  .accordion-title {
    position: relative;
    margin: 20px 0 0;
    padding: 15px 0 0;
    min-height: 45px;
    border-bottom: 2px solid $mercury;
    font-weight: 600;
    font-size: 16px;
    font-family: $proxima;

    button:focus,
    &.is-active button:focus {
      color: $teal;
    }

    &:hover,
    &.is-active:hover,
    &:focus,
    &.is-active:focus {
      border-bottom: 2px solid $bluechill;
      cursor: pointer;

      button {
        color: $teal;
      }

      span {
        border: 0;

        &:after {
          color: $teal;
        }
      }
    }

    &:first-of-type {
      margin-top: 0;
    }

    &.is-active {
      margin-bottom: 10px;
      border-bottom: 2px solid $ziggurat;

      button {
        color: $tundora;
        font-weight: 500;

        &:focus {
          font-weight: 500;
        }
      }

      span {
        border: 0;

        &:after {
          content: '\f068';
        }
      }
    }

    button {
      position: relative;
      top: 0;
      display: block;
      margin: 0;
      padding-left: 0;
      padding-right: 40px;
      width: 100%;
      height: 100%;
      border: 0;
      background: transparent;
      color: $tundora;
      text-align: left;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: 0;
      }
    }

    span {
      position: absolute;
      top: 0;
      right: 0;
      width: 45px;
      height: 100%;
      border: 0;

      &:after {
        position: absolute;
        top: 50%;
        left: 14px;
        width: 15px;
        height: 17px;
        color: $bluechill;
        content: '\f067';
        text-align: center;
        text-decoration: inherit;
        font-weight: normal;
        font-style: normal;
        font-family: FontAwesome;
        transform: translateY(-75%);
      }
    }
  }

  .accordion-title + [aria-hidden] {
    display: none;
  }

  .accordion-title + [aria-hidden='false'] {
    display: block;
  }

  .accordion-content {
    margin-top: 10px;
    padding: 0;
    color: $tundora;
    font-weight: 300;
    font-size: 16px;
  }

  .col {
    margin-bottom: 0 !important;
    .staff-preview {
      width: 100%;
    }
  }
}
