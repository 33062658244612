/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.element-invisible {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-for-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-for-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-for-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-for-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-for-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-for-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-for-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-for-portrait {
    display: block;
  }
}

@media (min-width: 640px) {
  .show-for-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .show-for-medium-only {
    display: none;
  }
}
@media (min-width: 1180px) {
  .show-for-medium-only {
    display: none;
  }
}

.show-for-large-only {
  display: none;
}
@media (min-width: 1180px) and (max-width: 1340px) {
  .show-for-large-only {
    display: block;
  }
}
@media (min-width: 1341px) {
  .show-for-large-only {
    display: none;
  }
}

.show-for-xlarge-only {
  display: none;
}
@media (min-width: 1341px) and (max-width: 1399px) {
  .show-for-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-for-xlarge-only {
    display: none;
  }
}

.show-for-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-for-xxlarge-only {
    display: none;
  }
}

.show-for-medium-up {
  display: none;
}
@media (min-width: 640px) {
  .show-for-medium-up {
    display: block;
  }
}

.show-for-large-up {
  display: none;
}
@media (min-width: 1180px) {
  .show-for-large-up {
    display: block;
  }
}

.show-for-xlarge-up {
  display: none;
}
@media (min-width: 1341px) {
  .show-for-xlarge-up {
    display: block;
  }
}

.show-for-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-for-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .hide-for-small-only {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1179px) {
  .hide-for-medium-only {
    display: none;
  }
}

@media (min-width: 1180px) and (max-width: 1340px) {
  .hide-for-large-only {
    display: none;
  }
}

@media (min-width: 1341px) and (max-width: 1399px) {
  .hide-for-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-for-xxlarge-only {
    display: none;
  }
}

@media (min-width: 640px) {
  .hide-for-medium-up {
    display: none;
  }
}

@media (min-width: 1180px) {
  .hide-for-large-up {
    display: none;
  }
}

@media (min-width: 1341px) {
  .hide-for-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-for-xxlarge-up {
    display: none;
  }
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

label {
  display: block;
  margin-bottom: 10px;
  color: #404040;
  font-weight: bold;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
}

button,
input[type=submit],
.btn.btn-primary {
  display: inline-block;
  width: 150px;
  height: 35px;
  border: 0;
  border-radius: 16px;
  background-color: #007f85;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: "Fira Sans", sans-serif;
  line-height: 35px;
}
button:hover,
input[type=submit]:hover,
.btn.btn-primary:hover {
  background-color: #595959;
  text-decoration: none;
  cursor: pointer;
}

.content-form .form-header {
  display: none;
}

.subpage .main .form-field {
  margin-top: 30px;
}
.subpage .main .form-field.checkbox {
  margin-top: 5px;
}
.subpage .main .form-field.checkbox label {
  font-weight: 400;
}
.subpage .main .form-field h3 {
  display: block;
  margin-bottom: 10px;
  color: #404040;
  font-weight: bold;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
}

input[type=text],
input[type=password],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=month],
input[type=week],
input[type=email],
input[type=number],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
textarea,
select {
  padding: 8px;
  width: 100%;
  border: 1px solid #d9d9d9;
  background: #fafafa;
  font-weight: 400;
  font-size: 14px;
  font-family: "Lexia", serif;
}

input[type=checkbox] {
  float: left;
  margin-top: 1px;
  margin-right: 10px;
}

input[type=submit] {
  margin-top: 30px;
}

div[id*=pnlCaptcha] {
  margin-top: 30px;
}

.label-left label {
  width: 25px;
  float: left;
  margin-top: 8px;
}
.label-left input {
  width: calc(100% - 25px);
}

.validation-summary {
  display: none;
}

.error-msg {
  color: #a63232;
  padding-top: 5px;
  font-style: italic;
}

.sm-full {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  width: 100%;
}

.sm-half {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 50%;
}

.sm-third {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 33.333%;
}

.sm-two-thirds {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 66.666%;
}

.sm-quarter {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 25%;
}

.sm-three-quarters {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 75%;
}

.sm-last + div {
  clear: both;
}

@media screen and (min-width: 40rem) {
  .sm-last + div {
    clear: none;
  }

  .md-full {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 100%;
  }

  .md-half {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 50%;
  }

  .md-third {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 33.333%;
  }

  .md-two-thirds {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 66.666%;
  }

  .md-quarter {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 25%;
  }

  .md-three-quarters {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 75%;
  }

  .md-last + div {
    clear: both;
  }
}
@media screen and (min-width: 64rem) {
  .sm-last + div {
    clear: none;
  }

  .md-last + div {
    clear: none;
  }

  .lg-full {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 100%;
  }

  .lg-half {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 50%;
  }

  .lg-third {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 33.333%;
  }

  .lg-two-thirds {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 66.666%;
  }

  .lg-quarter {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 25%;
  }

  .lg-three-quarters {
    clear: none;
    padding-right: 0.9375rem;
    width: 75%;
  }

  .lg-last + div {
    clear: both;
  }
}
.accordions {
  margin-bottom: 60px;
}
.accordions .accordion-title {
  position: relative;
  margin: 20px 0 0;
  padding: 15px 0 0;
  min-height: 45px;
  border-bottom: 2px solid #e6e6e6;
  font-weight: 600;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
}
.accordions .accordion-title button:focus, .accordions .accordion-title.is-active button:focus {
  color: #007f85;
}
.accordions .accordion-title:hover, .accordions .accordion-title.is-active:hover, .accordions .accordion-title:focus, .accordions .accordion-title.is-active:focus {
  border-bottom: 2px solid #097e88;
  cursor: pointer;
}
.accordions .accordion-title:hover button, .accordions .accordion-title.is-active:hover button, .accordions .accordion-title:focus button, .accordions .accordion-title.is-active:focus button {
  color: #007f85;
}
.accordions .accordion-title:hover span, .accordions .accordion-title.is-active:hover span, .accordions .accordion-title:focus span, .accordions .accordion-title.is-active:focus span {
  border: 0;
}
.accordions .accordion-title:hover span:after, .accordions .accordion-title.is-active:hover span:after, .accordions .accordion-title:focus span:after, .accordions .accordion-title.is-active:focus span:after {
  color: #007f85;
}
.accordions .accordion-title:first-of-type {
  margin-top: 0;
}
.accordions .accordion-title.is-active {
  margin-bottom: 10px;
  border-bottom: 2px solid #c1dfe1;
}
.accordions .accordion-title.is-active button {
  color: #404040;
  font-weight: 500;
}
.accordions .accordion-title.is-active button:focus {
  font-weight: 500;
}
.accordions .accordion-title.is-active span {
  border: 0;
}
.accordions .accordion-title.is-active span:after {
  content: "\f068";
}
.accordions .accordion-title button {
  position: relative;
  top: 0;
  display: block;
  margin: 0;
  padding-left: 0;
  padding-right: 40px;
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  color: #404040;
  text-align: left;
}
.accordions .accordion-title button:hover {
  cursor: pointer;
}
.accordions .accordion-title button:focus {
  outline: 0;
}
.accordions .accordion-title span {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  border: 0;
}
.accordions .accordion-title span:after {
  position: absolute;
  top: 50%;
  left: 14px;
  width: 15px;
  height: 17px;
  color: #097e88;
  content: "\f067";
  text-align: center;
  text-decoration: inherit;
  font-weight: normal;
  font-style: normal;
  font-family: FontAwesome;
  transform: translateY(-75%);
}
.accordions .accordion-title + [aria-hidden] {
  display: none;
}
.accordions .accordion-title + [aria-hidden=false] {
  display: block;
}
.accordions .accordion-content {
  margin-top: 10px;
  padding: 0;
  color: #404040;
  font-weight: 300;
  font-size: 16px;
}
.accordions .col {
  margin-bottom: 0 !important;
}
.accordions .col .staff-preview {
  width: 100%;
}

.subpage .main .tabs {
  margin: 0 0 50px 0;
}
.subpage .main .tabs .tab-list {
  display: none;
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
}
.subpage .main .tabs .tab-list[role=tablist] {
  display: block;
}
.subpage .main .tabs .tab-list li {
  display: inline-block;
  margin: 0 2px 0 0;
  padding: 0;
  white-space: nowrap;
}
.subpage .main .tabs .tab-list li a {
  display: block;
  padding: 15px 18px;
  border: 2px solid #e6e6e6;
  background: #f2f2f2;
  color: #404040;
  font-weight: 600;
  font-size: 14px;
  font-family: "Sanchez", serif;
}
.subpage .main .tabs .tab-list li a:hover {
  border: 2px solid #086b74;
  background: #097e88;
  color: #ffffff;
  text-decoration: none;
}
.subpage .main .tabs .tab-list li a:focus {
  text-decoration: none;
}
.subpage .main .tabs .tab-list li a[role=tab]:focus {
  border: 2px solid #086b74;
  background: #097e88;
  color: #ffffff;
}
.subpage .main .tabs .tab-list li a[aria-selected] {
  border: 2px solid #086b74;
  background: #097e88;
  color: #ffffff;
}
.subpage .main .tabs .tab-list li a[aria-selected]:hover {
  border: 2px solid #086b74;
  background: #097e88;
  color: #ffffff;
}
.subpage .main .tabs [role=tabpanel] {
  margin: -12px 0 0 4px;
  padding: 15px 18px;
  border: 2px solid #e6e6e6;
  background: #fafafa;
  color: #404040;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
}
.subpage .main .tabs [role=tabpanel][aria-hidden=true] {
  display: none;
}
.subpage .main .tabs [role=tabpanel] .tab-title {
  display: none;
}

.quiz {
  border: 1px solid gray;
}
.quiz .question {
  display: none;
}
.quiz .question:first-child {
  display: block;
}
.quiz .results {
  display: none;
}
.quiz .results .high-risk,
.quiz .results .low-risk,
.quiz .results .no-risk {
  display: none;
}

.subpage.contact-us .banner {
  max-height: 100px;
}
@media (min-width: 1180px) {
  .subpage.contact-us .banner {
    max-height: 145px;
  }
}

.contact-container {
  position: relative;
  left: -25px;
  display: flex;
  width: 100vw;
  background: #ffffff;
  flex-flow: column-reverse;
}
@media (min-width: 1180px) {
  .contact-container {
    left: 0;
    margin: 0 auto;
    max-width: 1064px;
    flex-flow: row;
  }
}
.contact-container .contact-info {
  padding: 40px 0 40px 25px;
  width: 100%;
  border-right: 1px solid #d9d9d9;
  background: rgba(255, 255, 255, 0.9);
}
@media (min-width: 1180px) {
  .contact-container .contact-info {
    margin-top: 75px;
    margin-bottom: 75px;
    padding: 0 60px 0 115px;
    width: 36%;
    background: transparent;
  }
}
.contact-container .contact-info h2 {
  position: relative;
  margin-bottom: 30px;
  font-size: 30px;
  font-family: "Archivo Black", sans-serif;
}
.contact-container .contact-info h3 {
  position: relative;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  font-family: "Fira Sans", sans-serif;
}
@media (min-width: 1180px) {
  .contact-container .contact-info h3:before {
    position: absolute;
    top: 0;
    left: -50px;
    width: 34px;
    height: 14px;
    background: transparent url("../images/short-blue-diagonal.png") no-repeat center center;
    content: "";
  }
}
.contact-container .contact-info ul {
  margin-left: 0;
  padding-left: 20px;
}
.contact-container .contact-info ul li {
  margin: 20px 0;
}
.contact-container .contact-info .diagonal-border-thick {
  background: transparent;
}
.contact-container .contact-info .diagonal-border-thick:after {
  content: none;
}
.contact-container .contact-content {
  padding: 40px 0 40px 25px;
  width: 100%;
}
@media (min-width: 1180px) {
  .contact-container .contact-content {
    margin-top: 45px;
    margin-bottom: 75px;
    padding: 0 60px 0 115px;
    width: 63%;
    background: #ffffff;
  }
}

.project-listing {
  margin: 0 0 0 -25px;
  width: 100vw;
}
@media screen and (min-width: 1180px) {
  .project-listing {
    display: flex;
    margin: 0;
    flex-wrap: wrap;
  }
}
.project-listing .row-fill {
  display: none;
}
@media screen and (min-width: 640px) {
  .project-listing .row-fill:nth-child(even) {
    display: block;
    border-bottom: 1px solid #fff;
    background: #f2f2f2 url("../images/striped-background.gif") repeat top left;
    flex-grow: 1;
  }
}
.project-listing .project-nav-link {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 1px;
  height: 235px;
  background: #dedede;
}
.project-listing .project-nav-link:before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  content: "";
}
.project-listing .project-nav-link:hover:before {
  background: rgba(0, 0, 0, 0.75);
}
@media screen and (min-width: 1180px) {
  .project-listing .project-nav-link {
    margin-right: 1px;
    width: calc(50% - 1px);
    height: auto;
    height: 325px;
  }
  .project-listing .project-nav-link:nth-child(even) {
    margin-right: 0;
    width: 50%;
  }
}
.project-listing .project-nav-link img {
  position: relative;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 100%;
  transform: translateX(-50%) translateY(-50%);
}
.project-listing .nav-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  padding-bottom: 5px;
  border-bottom: 2px solid #f0af00;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 24px;
  font-family: "Archivo Black", sans-serif;
  line-height: 1.25em;
  transform: translateX(-50%) translateY(-50%);
}

.subpage.experience.banner-collapse .banner {
  max-height: 256px;
}
@media (min-width: 1180px) {
  .subpage.experience.banner-collapse .banner {
    max-height: 145px;
  }
}

.project-details-container {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 1180px) {
  .project-details-container {
    flex-direction: row;
    margin-bottom: 2.5rem;
  }
}
.project-details-container .left-column {
  margin-top: 75px;
  margin-bottom: 75px;
  width: calc(100% - 75px);
}
@media (min-width: 1180px) {
  .project-details-container .left-column {
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 75px;
    width: 43%;
  }
}
.project-details-container .right-column {
  overflow: visible;
  width: 100%;
}
@media (min-width: 1180px) {
  .project-details-container .right-column {
    overflow: hidden;
    width: 57%;
  }
}
.project-details-container .background-image {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  min-width: 110%;
  min-height: 110%;
  filter: blur(15px);
  transform: translateX(-50%) translateY(-50%);
}
.project-details-container .display-image {
  position: relative;
  z-index: 1;
  border: 0;
}
.project-details-container .diagonal-border-thick {
  background: transparent;
}
.project-details-container .project-title {
  position: absolute;
  top: 100px;
  margin-left: -25px;
  padding: 40px 0 40px 25px;
  width: calc(100% + 50px);
  background: rgba(255, 255, 255, 0.9);
}
@media (min-width: 1180px) {
  .project-details-container .project-title {
    position: relative;
    top: auto;
    margin: 0;
    padding: 40px 0 40px 115px;
    width: auto;
    background: #f2f2f2;
  }
}
.project-details-container .project-title h2 {
  position: relative;
  margin-bottom: 30px;
  font-size: 30px;
  font-family: "Archivo Black", sans-serif;
}
.project-details-container .overview {
  position: relative;
  margin-top: -156px;
  padding: 40px 0 0 25px;
  padding-left: 0;
  width: 100%;
  background: #f2f2f2;
  background: transparent url("../images/blue-diagonal-horizontal.png") repeat-x top left;
  font-size: 16px;
}
.project-details-container .overview:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 14px solid #fff;
  border-right: 14px solid transparent;
  content: "";
}
@media (min-width: 1180px) {
  .project-details-container .overview:before {
    content: none;
  }
}
.project-details-container .overview:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-right: 2px solid #fff;
  border-bottom: 14px solid #fff;
  border-left: 14px solid transparent;
  content: "";
}
@media (min-width: 1180px) {
  .project-details-container .overview {
    margin-top: 0;
    padding: 20px 60px 0 115px;
    background: white;
  }
}
.project-details-container .overview h2 {
  position: relative;
  margin-bottom: 30px;
  font-size: 30px;
  font-family: "Archivo Black", sans-serif;
}
.project-details-container .overview h3 {
  position: relative;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  font-family: "Fira Sans", sans-serif;
}
.project-details-container .overview h3:before {
  content: none;
}
@media (min-width: 1180px) {
  .project-details-container .overview h3:before {
    position: absolute;
    top: 0;
    left: -50px;
    width: 34px;
    height: 14px;
    background: transparent url("../images/short-blue-diagonal.png") no-repeat center center;
    content: "";
  }
}
.project-details-container .overview ul {
  margin-left: 0;
  padding-left: 20px;
}
.project-details-container .overview ul li {
  margin: 20px 0;
}
.project-details-container .diagonal-border-thick:after {
  content: none;
}
.project-details-container .gallery {
  margin: 0 -25px;
  width: 100vw;
}
@media (min-width: 1180px) {
  .project-details-container .gallery {
    margin: 0;
    width: 100%;
  }
}
.project-details-container .gallery img {
  margin: 0;
  width: auto;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}
.project-details-container .gallery img.no-blur {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
}
.project-details-container .featured-image {
  position: relative;
  display: none;
  overflow: hidden;
  margin-bottom: 0;
  max-height: 700px;
}
@media (min-width: 640px) {
  .project-details-container .featured-image {
    display: block;
  }
}
.project-details-container .featured-image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 700px;
  border: 0;
}
.project-details-container h3.gallery-title {
  position: absolute;
  bottom: 3px;
  z-index: 2;
  display: none;
  margin: 0;
  padding: 15px 30px;
  background: #16181c;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 18px;
  font-family: "Fira Sans", sans-serif;
}
@media (min-width: 1180px) {
  .project-details-container h3.gallery-title {
    display: block;
  }
}
.project-details-container .thumbnail-container {
  position: relative;
  overflow: hidden;
  height: 115px;
}
.project-details-container .thumbnail-container .overflowing .show-all-images {
  display: block;
}
.project-details-container .thumbnail-container .show-all-images {
  position: absolute;
  top: 0;
  right: 0;
  width: 111px;
  height: 115px;
  background: rgba(0, 0, 0, 0.5);
  color: #f2f2f2;
  text-align: center;
  line-height: 115px;
  cursor: pointer;
}
.project-details-container .thumbnail-container .show-all-images .fa {
  font-size: 34px;
  line-height: 115px;
}
.project-details-container .thumbnail-container .show-all-images .fa.fa-minus {
  display: none;
}
.project-details-container .thumbnail-container .show-all-images .hidden-text {
  display: block;
  visibility: hidden;
}
.project-details-container .thumbnail-container.show-all {
  overflow: visible;
}
.project-details-container .thumbnail-container.show-all .thumbnails {
  z-index: 1;
  flex-wrap: wrap;
}
.project-details-container .thumbnail-container.show-all .show-all-images {
  height: 100%;
  background: #404040;
}
.project-details-container .thumbnail-container.show-all .show-all-images .fa {
  height: 100%;
}
.project-details-container .thumbnail-container.show-all .fa.fa-plus {
  display: none;
}
.project-details-container .thumbnail-container.show-all .fa.fa-minus {
  display: block;
}
.project-details-container .thumbnail-container.show-all .fa.fa-minus:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.project-details-container .mobile-carousel {
  display: block;
}
@media (min-width: 640px) {
  .project-details-container .mobile-carousel {
    display: none;
  }
}
.project-details-container .carousel-nav {
  position: relative;
  top: -55px;
}
.project-details-container .carousel-nav .mobile-slider-nav.right, .project-details-container .carousel-nav .mobile-slider-nav.left {
  position: absolute;
  top: 50%;
  margin: 0;
  width: 65px;
  height: 65px;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f2f2f2;
  text-align: center;
  cursor: pointer;
  transform: translateY(-50%);
}
@media (min-width: 640px) {
  .project-details-container .carousel-nav .mobile-slider-nav.right, .project-details-container .carousel-nav .mobile-slider-nav.left {
    display: none;
  }
}
.project-details-container .carousel-nav .mobile-slider-nav.right {
  right: 0;
}
.project-details-container .carousel-nav .mobile-slider-nav.left {
  left: 0;
}
.project-details-container .carousel-nav .mobile-slider-nav .fa {
  margin: 0;
  width: 65px;
  height: 100%;
  font-size: 34px;
  line-height: 65px;
}
.project-details-container .carousel-nav .mobile-slider-nav .hidden-text {
  display: block;
  visibility: hidden;
}
.project-details-container .thumbnails {
  position: relative;
  display: none;
  padding-right: 111px;
  width: 100%;
  background: #f2f2f2 url("../dist/images/striped-background.gif") repeat top left;
  flex-wrap: nowrap;
}
@media (min-width: 640px) {
  .project-details-container .thumbnails {
    display: flex;
  }
}
.project-details-container .thumbnails span {
  position: relative;
  margin: 0 25px;
}
@media (min-width: 640px) {
  .project-details-container .thumbnails span {
    margin: 0;
  }
}
.project-details-container .thumbnails span:focus:after {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.25;
}
.project-details-container .thumbnails img {
  width: auto;
  height: 115px;
}
.project-details-container .thumbnails .row-fill {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  flex-grow: 1;
}
.project-details-container .description {
  padding: 75px 0 160px;
  width: 100%;
}
@media (min-width: 1180px) {
  .project-details-container .description {
    padding: 75px 56px;
    border-left: 1px solid #d9d9d9;
  }
}
.project-details-container .description h2 {
  margin-bottom: 14px;
  font-size: 30px;
  font-family: "Archivo Black", sans-serif;
}
.project-details-container .description img {
  margin: 0;
}
.project-details-container .services {
  position: relative;
  padding-top: 0;
  padding-left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1180px) {
  .project-details-container .services {
    padding: 0 60px 0 110px;
    background: #fff;
  }
}
.project-details-container .services h3 {
  position: relative;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  font-family: "Fira Sans", sans-serif;
}
.project-details-container .services h3:before {
  content: none;
}
@media (min-width: 1180px) {
  .project-details-container .services h3:before {
    position: absolute;
    top: 0;
    left: -50px;
    width: 34px;
    height: 14px;
    background: transparent url("../images/short-blue-diagonal.png") no-repeat center center;
    content: "";
  }
}
.project-details-container .services ul {
  margin-left: 0;
  padding-left: 20px;
}
.project-details-container .services ul li {
  margin: 20px 0;
}
.project-details-container .services p {
  font-size: 16px;
  line-height: 28px;
}
.project-details-container .call-to-action {
  position: relative;
  margin-top: 80px;
  padding: 40px 25px;
  border: 5px solid #e6e6e6;
  background: #fafafa;
}
@media (min-width: 1180px) {
  .project-details-container .call-to-action {
    padding: 45px 50px;
  }
}
.project-details-container .call-to-action:before {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  border-top: 75px solid #e6e6e6;
  border-right: 0;
  border-bottom: 0;
  border-left: 75px solid #fafafa;
  content: "";
}
.project-details-container .call-to-action:after {
  position: absolute;
  top: 5px;
  right: 8px;
  color: #b3b3b3;
  content: "\f003";
  font-size: 24px;
  font-family: FontAwesome;
}
.project-details-container .call-to-action h2 {
  margin-bottom: 14px;
  font-size: 24px;
  font-family: "Archivo Black", sans-serif;
}
.project-details-container .call-to-action p {
  margin-bottom: 20px;
}
.project-details-container .call-to-action .btn.btn-primary {
  width: 225px;
  font-size: 14px;
}

.subpage.no-blur .project-details-container .gallery img.has-blur {
  display: none;
}
.subpage.no-blur .project-details-container .gallery img.no-blur {
  display: block;
}

@media (min-width: 1180px) {
  .subpage.project-detail section.footer {
    padding-top: 324px;
  }
  .subpage.project-detail .project-listing-related {
    position: absolute;
    left: 0;
  }
}

.capabilities h2.no-content {
  display: none;
}
.capabilities .main .ul1 {
  width: 100vw;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
.capabilities .main .ul1 .row-fill {
  display: none;
}
@media screen and (min-width: 640px) {
  .capabilities .main .ul1 .row-fill:not(:nth-child(5n+1)) {
    display: block;
    border-bottom: 1px solid #ffffff;
    background: #f2f2f2 url("../images/striped-background.gif") repeat top left;
    flex-grow: 1;
  }
}
.capabilities .main .ul1 .row-fill:after {
  content: none;
}
@media screen and (max-width: 640px) {
  .capabilities .main .ul1.one-col {
    margin-left: -25px;
  }
}
.capabilities .main .ul1.one-col .row-fill {
  display: none;
}
.capabilities .main .ul1.one-col > li {
  width: 100vw;
  margin-right: 0;
}
.capabilities .main .ul1.one-col > li:nth-child(5n) {
  width: 100vw;
  margin-right: 0;
}
@media screen and (max-width: 640px) {
  .capabilities .main .ul1.two-col {
    margin-left: -25px;
  }
}
.capabilities .main .ul1.two-col .row-fill {
  display: none;
}
.capabilities .main .ul1.two-col > li {
  width: 100vw;
  margin-right: 0;
}
.capabilities .main .ul1.two-col > li:nth-child(5n) {
  width: 100vw;
  margin-right: 0;
}
@media screen and (min-width: 1180px) and (max-width: 640px) {
  .capabilities .main .ul1.two-col {
    margin-left: -25px;
  }
}
@media (min-width: 1180px) {
  .capabilities .main .ul1.two-col .row-fill {
    display: none;
  }
}
@media screen and (min-width: 1180px) and (min-width: 640px) {
  .capabilities .main .ul1.two-col .row-fill:not(:nth-child(2n+1)) {
    display: block;
    border-bottom: 1px solid #ffffff;
    background: #f2f2f2 url("../images/striped-background.gif") repeat top left;
    flex-grow: 1;
  }
}
@media (min-width: 1180px) {
  .capabilities .main .ul1.two-col > li {
    width: calc(50vw - 1px);
  }
  .capabilities .main .ul1.two-col > li:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 640px) {
  .capabilities .main .ul1.three-col {
    margin-left: -25px;
  }
}
.capabilities .main .ul1.three-col .row-fill {
  display: none;
}
.capabilities .main .ul1.three-col > li {
  width: 100vw;
  margin-right: 0;
}
.capabilities .main .ul1.three-col > li:nth-child(5n) {
  width: 100vw;
  margin-right: 0;
}
@media screen and (min-width: 640px) and (max-width: 640px) {
  .capabilities .main .ul1.three-col {
    margin-left: -25px;
  }
}
@media (min-width: 640px) {
  .capabilities .main .ul1.three-col .row-fill {
    display: none;
  }
}
@media screen and (min-width: 640px) and (min-width: 640px) {
  .capabilities .main .ul1.three-col .row-fill:not(:nth-child(3n+1)) {
    display: block;
    border-bottom: 1px solid #ffffff;
    background: #f2f2f2 url("../images/striped-background.gif") repeat top left;
    flex-grow: 1;
  }
}
@media (min-width: 640px) {
  .capabilities .main .ul1.three-col > li {
    width: 33.1125827815vw;
  }
  .capabilities .main .ul1.three-col > li:nth-child(3n) {
    margin-right: 0;
  }
  .capabilities .main .ul1.three-col > li:nth-child(5n) {
    width: 33.1125827815vw;
    margin-right: 1px;
  }
}
@media screen and (max-width: 640px) {
  .capabilities .main .ul1.four-col {
    margin-left: -25px;
  }
}
.capabilities .main .ul1.four-col .row-fill {
  display: none;
}
.capabilities .main .ul1.four-col > li {
  width: 100vw;
  margin-right: 0;
}
.capabilities .main .ul1.four-col > li:nth-child(5n) {
  width: 100vw;
  margin-right: 0;
}
@media screen and (min-width: 640px) and (max-width: 640px) {
  .capabilities .main .ul1.four-col {
    margin-left: -25px;
  }
}
@media (min-width: 640px) {
  .capabilities .main .ul1.four-col .row-fill {
    display: none;
  }
}
@media screen and (min-width: 640px) and (min-width: 640px) {
  .capabilities .main .ul1.four-col .row-fill:not(:nth-child(4n+1)) {
    display: block;
    border-bottom: 1px solid #ffffff;
    background: #f2f2f2 url("../images/striped-background.gif") repeat top left;
    flex-grow: 1;
  }
}
@media (min-width: 640px) {
  .capabilities .main .ul1.four-col > li {
    width: calc(25vw - 1px);
  }
  .capabilities .main .ul1.four-col > li:nth-child(4n) {
    margin-right: 0;
  }
}
@media (max-width: 1180px) {
  .capabilities .main .ul1.five-col {
    margin-left: -25px;
  }
}
@media screen and (max-width: 1180px) and (max-width: 640px) {
  .capabilities .main .ul1.five-col {
    margin-left: -25px;
  }
}
@media (max-width: 1180px) {
  .capabilities .main .ul1.five-col .row-fill {
    display: none;
  }
}
@media screen and (max-width: 1180px) and (min-width: 640px) {
  .capabilities .main .ul1.five-col .row-fill:not(:nth-child(3n+1)) {
    display: block;
    border-bottom: 1px solid #ffffff;
    background: #f2f2f2 url("../images/striped-background.gif") repeat top left;
    flex-grow: 1;
  }
}
@media (max-width: 1180px) {
  .capabilities .main .ul1.five-col > li {
    width: 33.1125827815vw;
  }
  .capabilities .main .ul1.five-col > li:nth-child(3n) {
    margin-right: 0;
  }
  .capabilities .main .ul1.five-col > li:nth-child(5n) {
    width: 33.1125827815vw;
    margin-right: 1px;
  }
}
@media screen and (max-width: 640px) and (max-width: 640px) {
  .capabilities .main .ul1.five-col {
    margin-left: -25px;
  }
}
@media (max-width: 640px) {
  .capabilities .main .ul1.five-col .row-fill {
    display: none;
  }
}
@media (max-width: 640px) {
  .capabilities .main .ul1.five-col > li {
    width: 100vw;
    margin-right: 0;
  }
  .capabilities .main .ul1.five-col > li:nth-child(5n) {
    width: 100vw;
    margin-right: 0;
  }
}
.capabilities .main .ul1 > li {
  position: relative;
  width: calc(20vw - 1px);
  height: 20vw;
  background: #666;
  overflow: hidden;
  margin: 0 1px 1px 0;
  padding: 0;
}
.capabilities .main .ul1 > li:after {
  position: absolute;
  width: 100%;
  content: "";
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.55);
  z-index: 1;
}
.capabilities .main .ul1 > li:hover {
  cursor: pointer;
}
.capabilities .main .ul1 > li:hover:after {
  background: rgba(0, 0, 0, 0.75);
}
.capabilities .main .ul1 > li:nth-child(5n) {
  margin-right: 0px;
  width: 20vw;
}
.capabilities .main .ul1 > li a {
  position: relative;
  height: 100%;
  display: block;
  transform: none;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  margin: 0 auto;
  border: 0;
}
.capabilities .main .ul1 > li a > span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  padding-bottom: 5px;
  border-bottom: 2px solid #f0af00;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-align: center;
  line-height: 1.5em;
  max-width: calc(100% - 40px);
  font-size: 14px;
  font-family: lexia-advertising;
}
.capabilities .main .ul1 > li a:hover {
  text-decoration: none;
}
.capabilities .main .ul1 > li img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  margin: 0;
  top: 50%;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
.capabilities .nav-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  padding-bottom: 5px;
  border-bottom: 2px solid #f0af00;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1.25em;
  letter-spacing: 1.5px;
  font-size: 24px;
  font-family: "Archivo Black", sans-serif;
  transform: translateX(-50%) translateY(-50%);
}

.capabilities-detail-container {
  position: relative;
}
.capabilities-detail-container .page-container {
  padding-bottom: 90px;
  margin-bottom: 145px;
}
.capabilities-detail-container .capabilities-container img {
  display: none;
}
.capabilities-detail-container .row-fill {
  display: none;
}
@media screen and (min-width: 640px) {
  .capabilities-detail-container .row-fill:nth-child(-n+4) {
    display: block;
    border-bottom: 1px solid #ffffff;
    background: #f2f2f2 url("../images/striped-background.gif") repeat top left;
    flex-grow: 1;
  }
}
.capabilities-detail-container .image-navigation {
  height: 145px;
  position: absolute;
  left: 0;
  bottom: -145px;
}
.capabilities-detail-container .image-navigation .image-nav-link {
  overflow: hidden;
}
.capabilities-detail-container .image-navigation .image-nav-link img {
  margin: 0;
}

body.modal-open {
  overflow: hidden;
  margin-left: 0;
  width: 100vw;
}

.staff-preview {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 15px;
  margin-bottom: 30px;
  width: calc(50% - 18px);
  border: 1px solid #fafafa;
  background: #f2f2f2;
}
@media (max-width: 640px) {
  .staff-preview {
    margin: 0;
    width: 100%;
  }
}
.staff-preview:nth-child(even) {
  margin-right: 0;
  margin-left: 15px;
}
@media (max-width: 640px) {
  .staff-preview:nth-child(even) {
    margin: 0;
  }
}
.staff-preview .img-container {
  overflow: hidden;
  margin: -1px -1px 25px -1px;
  width: calc(100% + 2px);
  height: 270px;
  background: transparent url("../images/elpruitt-default-image.png") no-repeat center center;
  background-size: cover;
}
@media (max-width: 320px) {
  .staff-preview .img-container {
    height: 240px;
  }
}
.staff-preview img.picture {
  position: relative;
  top: 0;
  left: 50%;
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
  transform: translateY(0) translateX(-50%);
}
.staff-preview img.picture[src="{{image}}"] {
  display: none;
}
.staff-preview .details {
  text-align: center;
}
.staff-preview .details h4 {
  margin: 10px;
  color: #262626;
  font-weight: 800;
  font-size: 24px;
  font-family: "Fira Sans", sans-serif;
}
.staff-preview .details .job-title {
  color: #737373;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
  line-height: 18px;
}
.staff-preview .details hr {
  display: block;
  clear: both;
  margin: 25px auto 15px;
  width: 106px;
  height: 5px;
  border: 0;
  background: transparent url("../images/staff-thumb-diagonal.png") no-repeat center center;
}
.staff-preview .details button.txt-button {
  margin-bottom: 20px;
  width: auto;
  height: auto;
  background: transparent;
  color: #007f85;
  font-weight: 600;
  font-size: 14px;
  line-height: 1em;
}
.staff-preview .hover-panel {
  position: absolute;
  top: -100%;
  width: 100%;
  height: 100%;
  background: #097e88;
  transition: top 0.35s ease-in-out;
}
.staff-preview .hover-panel h4.name {
  margin-top: 133px;
  margin-bottom: 5px;
  color: #fff;
  text-align: center;
  font-size: 24px;
}
.staff-preview .hover-panel .title {
  margin: 5px 60px;
  margin-bottom: 60px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
.staff-preview .hover-panel .txt-button.view-info {
  position: absolute;
  bottom: 90px;
  left: 50%;
  font-weight: 700;
  font-size: 14px;
  transform: translateX(-50%);
}
.staff-preview .hover-panel .txt-button.view-info:hover {
  background: transparent;
}
.staff-preview .hover-panel .txt-button.view-info:before {
  position: absolute;
  top: -35px;
  left: 50%;
  display: block;
  width: 36px;
  height: 36px;
  background: transparent url("../images/view-details.png") no-repeat center center;
  content: "";
  transform: translateX(-50%);
}
@media (min-width: 941px) {
  .staff-preview:hover .hover-panel {
    top: 0;
  }
}

.screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.85);
}

.staff-bio-detail .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  padding: 35px 55px 0;
  width: 786px;
  background: rgba(9, 118, 128, 0.95) linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(130, 130, 130, 0) 51%, rgba(255, 255, 255, 0) 100%);
  color: #fff;
  transform: translateX(-50%) translateY(-50%);
}
@media (max-width: 940px) {
  .staff-bio-detail .modal {
    max-width: 90vw;
  }
}
@media (max-width: 640px) {
  .staff-bio-detail .modal {
    position: static;
    overflow-y: auto;
    padding-top: 90px;
    max-width: none;
    max-height: 100vh;
    width: 100vw;
    border: none;
    transform: none;
  }
}
.staff-bio-detail .modal .close-btn {
  position: absolute;
  top: -25px;
  right: -25px;
  box-sizing: content-box;
  width: 48px;
  height: 48px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #0a7680;
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
  cursor: pointer;
}
@media (max-width: 640px) {
  .staff-bio-detail .modal .close-btn {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 55px;
    border: 0;
    border-radius: 0;
    background: #064d53;
    line-height: 55px;
  }
}
.staff-bio-detail .modal .close-btn .mobile-text {
  display: none;
  vertical-align: top;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 18px;
  font-family: "Fira Sans", sans-serif;
}
@media (max-width: 640px) {
  .staff-bio-detail .modal .close-btn .mobile-text {
    display: inline;
  }
}
.staff-bio-detail .modal .staffImg-container {
  position: absolute;
  top: -80px;
  left: -112px;
  overflow: hidden;
  width: 335px;
  height: 250px;
  box-shadow: 1px 1px 60px 10px rgba(0, 0, 0, 0.15);
}
@media (max-width: 940px) {
  .staff-bio-detail .modal .staffImg-container {
    display: none;
  }
}
.staff-bio-detail .modal .staffImg-container img {
  position: absolute;
  top: 0;
  left: 50%;
  min-height: 100%;
  width: 100%;
  transform: translateX(-50%) translateY(0);
}
.staff-bio-detail .modal .nametext,
.staff-bio-detail .modal .titletext,
.staff-bio-detail .modal .exptext {
  padding-left: 225px;
}
@media (max-width: 940px) {
  .staff-bio-detail .modal .nametext,
.staff-bio-detail .modal .titletext,
.staff-bio-detail .modal .exptext {
    padding-left: 0;
  }
}
@media (max-width: 640px) {
  .staff-bio-detail .modal .nametext,
.staff-bio-detail .modal .titletext,
.staff-bio-detail .modal .exptext {
    text-align: center;
  }
}
.staff-bio-detail .modal .nametext {
  margin-bottom: 6px;
  font-weight: 800;
  font-size: 24px;
}
.staff-bio-detail .modal .titletext {
  margin-bottom: 15px;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 16px;
}
@media (max-width: 940px) {
  .staff-bio-detail .modal .titletext {
    white-space: pre-wrap;
  }
}
.staff-bio-detail .modal .exptext {
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
  font-family: "Lexia", serif;
}
.staff-bio-detail .modal .divider {
  position: relative;
  z-index: -1;
  margin: 38px -55px 55px 55px;
  width: 100%;
  height: 20px;
  background: transparent url("../images/staff-bio-divider.png") repeat-x;
  background-size: auto 100%;
  opacity: 0.25;
}
@media (max-width: 940px) {
  .staff-bio-detail .modal .divider {
    margin: 30px 0 25px -55px;
    width: calc(100% + 110px);
    opacity: 1;
  }
}
.staff-bio-detail .modal .biotext {
  margin-bottom: 68px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5em;
}
@media (max-width: 640px) {
  .staff-bio-detail .modal .biotext {
    text-align: center;
  }
}
.staff-bio-detail .modal .contact-details {
  display: flex;
  margin: 0 -55px;
  width: calc(100% + 110px);
  background: rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}
.staff-bio-detail .modal .contact-details > div {
  display: flex;
  width: 50%;
  height: 57px;
  border: 2px solid #509ea4;
  border-right: 0;
  border-bottom: 0;
  line-height: 57px;
  justify-content: center;
}
.staff-bio-detail .modal .contact-details > div:nth-child(odd) {
  border-left: 0;
}
@media (max-width: 640px) {
  .staff-bio-detail .modal .contact-details > div {
    width: 100%;
    border-right: 0;
    border-left: 0;
  }
  .staff-bio-detail .modal .contact-details > div:last-child {
    border-bottom: 2px solid #509ea4;
  }
}
.staff-bio-detail .modal .contact-details > div .fa {
  margin-right: 10px;
  color: #72afb5;
  font-size: 20px;
  line-height: 57px;
}
.staff-bio-detail .modal .contact-details > div .fa.fa-mobile {
  font-size: 22px;
}
.staff-bio-detail .modal .contact-details > div .fa.fa-print {
  font-size: 18px;
}
.staff-bio-detail .modal .contact-details > div .fa.fa-envelope {
  font-size: 14px;
}
.staff-bio-detail .modal .contact-details > div label {
  margin-right: 5px;
  color: #fff;
}
.staff-bio-detail .modal .contact-details > div span[class*=Text] {
  font-weight: 400;
}

.two-column,
.three-column,
.four-column,
.five-column,
.six-column,
ul.two-column,
ul.three-column,
ul.four-column,
ul.five-column,
ul.six-column {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
@media (min-width: 640px) {
  .two-column,
.three-column,
.four-column,
.five-column,
.six-column,
ul.two-column,
ul.three-column,
ul.four-column,
ul.five-column,
ul.six-column {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.two-column .col,
.two-column li.col,
.three-column .col,
.three-column li.col,
.four-column .col,
.four-column li.col,
.five-column .col,
.five-column li.col,
.six-column .col,
.six-column li.col,
ul.two-column .col,
ul.two-column li.col,
ul.three-column .col,
ul.three-column li.col,
ul.four-column .col,
ul.four-column li.col,
ul.five-column .col,
ul.five-column li.col,
ul.six-column .col,
ul.six-column li.col {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 2.5rem;
}
.two-column li.col,
.three-column li.col,
.four-column li.col,
.five-column li.col,
.six-column li.col,
ul.two-column li.col,
ul.three-column li.col,
ul.four-column li.col,
ul.five-column li.col,
ul.six-column li.col {
  margin: 0;
  padding: 0;
}
.two-column li.col:before,
.three-column li.col:before,
.four-column li.col:before,
.five-column li.col:before,
.six-column li.col:before,
ul.two-column li.col:before,
ul.three-column li.col:before,
ul.four-column li.col:before,
ul.five-column li.col:before,
ul.six-column li.col:before {
  display: none;
}

@media (min-width: 640px) {
  .two-column .col,
ul.two-column li.col {
    width: calc(50% - 20px);
  }
}

@media (min-width: 640px) {
  .three-column .col,
ul.three-column li.col {
    width: calc(50% - 20px);
  }
}
@media (min-width: 1180px) {
  .three-column .col,
ul.three-column li.col {
    width: calc(33.3333333333% - 20px);
  }
}

@media (min-width: 640px) {
  .four-column .col,
ul.four-column li.col {
    width: calc(50% - 20px);
  }
}
@media (min-width: 1180px) {
  .four-column .col,
ul.four-column li.col {
    width: calc(25% - 20px);
  }
}

@media (min-width: 640px) {
  .five-column .col,
ul.five-column li.col {
    width: calc(50% - 20px);
  }
}
@media (min-width: 1180px) {
  .five-column .col,
ul.five-column li.col {
    width: calc(20% - 20px);
  }
}

@media (min-width: 640px) {
  .six-column .col,
ul.six-column li.col {
    width: calc(50% - 20px);
  }
}
@media (min-width: 1180px) {
  .six-column .col,
ul.six-column li.col {
    width: calc(25% - 20px);
  }
}
@media (min-width: 1341px) {
  .six-column .col,
ul.six-column li.col {
    width: calc(16.6666666667% - 20px);
  }
}

.subpage .main .album img {
  margin: 0;
}
.subpage .main .album a {
  display: block;
}
.subpage .main .album img {
  max-width: 100%;
  height: auto;
}