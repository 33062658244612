fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

label {
  display: block;
  margin-bottom: 10px;
  color: $tundora;
  font-weight: bold;
  font-size: 16px;
  font-family: $proxima;
}

button,
input[type='submit'],
.btn.btn-primary {
  display: inline-block;
  width: 150px;
  height: 35px;
  border: 0;
  border-radius: 16px;
  background-color: $teal;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  font-family: $proxima;
  line-height: 35px;

  &:hover {
    background-color: $scorpion;
    text-decoration: none;
    cursor: pointer;
  }
}

.content-form .form-header {
  display: none;
}

.subpage .main .form-field {
  margin-top: 30px;

  &.checkbox {
    margin-top: 5px;

    label {
      font-weight: 400;
    }
  }

  h3 {
    display: block;
    margin-bottom: 10px;
    color: $tundora;
    font-weight: bold;
    font-size: 16px;
    font-family: $proxima;
  }
}

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
textarea,
select {
  padding: 8px;
  width: 100%;
  border: 1px solid $alto;
  background: $alabaster2;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Lexia', serif;
}

input[type='checkbox'] {
  float: left;
  margin-top: 1px;
  margin-right: 10px;
}

input[type='submit'] {
  margin-top: 30px;
}

div[id*='pnlCaptcha'] {
  margin-top: 30px;
}

.label-left {
  label {
    width: 25px;
    float: left;
    margin-top: 8px;
  }

  input {
    width: calc(100% - 25px);
  }
}

.validation-summary {
  display: none;
}

.error-msg {
  color: $stiletto;
  padding-top: 5px;
  font-style: italic;
}

.sm-full {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  width: 100%;
}

.sm-half {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 50%;
}

.sm-third {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 33.333%;
}

.sm-two-thirds {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 66.666%;
}

.sm-quarter {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 25%;
}

.sm-three-quarters {
  float: left;
  clear: none;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  width: 75%;
}

.sm-last + div {
  clear: both;
}

@media screen and (min-width: 40rem) {
  .sm-last + div {
    clear: none;
  }

  .md-full {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 100%;
  }

  .md-half {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 50%;
  }

  .md-third {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 33.333%;
  }

  .md-two-thirds {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 66.666%;
  }

  .md-quarter {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 25%;
  }

  .md-three-quarters {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 75%;
  }

  .md-last + div {
    clear: both;
  }
}

@media screen and (min-width: 64rem) {
  .sm-last + div {
    clear: none;
  }

  .md-last + div {
    clear: none;
  }

  .lg-full {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 100%;
  }

  .lg-half {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 50%;
  }

  .lg-third {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 33.333%;
  }

  .lg-two-thirds {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 66.666%;
  }

  .lg-quarter {
    float: left;
    clear: none;
    padding-right: 0.9375rem;
    width: 25%;
  }

  .lg-three-quarters {
    clear: none;
    padding-right: 0.9375rem;
    width: 75%;
  }

  .lg-last + div {
    clear: both;
  }
}
