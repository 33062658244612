body.modal-open {
    overflow: hidden;
    margin-left: 0;
    width: 100vw;
}

.staff-preview {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin-right: 15px;
    margin-bottom: 30px;
    width: calc(50% - 18px);
    border: 1px solid $alabaster2;
    background: $concrete;

    @media (max-width: 640px) {
        margin: 0;
        width: 100%;
    }

    &:nth-child(even) {
        margin-right: 0;
        margin-left: 15px;

        @media (max-width: 640px) {
            margin: 0;
        }
    }

    .img-container {
        overflow: hidden;
        margin: -1px -1px 25px -1px;
        width: calc(100% + 2px);
        height: 270px;
        background: transparent url('../images/elpruitt-default-image.png') no-repeat center center;
        background-size: cover;

        @media (max-width: 320px) {
            height: 240px;
        }
    }

    img.picture {
        position: relative;
        top: 0;
        left: 50%;
        margin: 0;
        padding: 0;
        min-height: 100%;
        width: 100%;
        transform: translateY(0) translateX(-50%);

        &[src='{{image}}'] {
            display: none;
        }
    }

    .details {
        text-align: center;

        h4 {
            margin: 10px;
            color: #262626;
            font-weight: 800;
            font-size: 24px;
            font-family: $proxima;
        }

        .job-title {
            color: $dovegray2;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
            font-family: $proxima;
            line-height: 18px;
        }

        hr {
            display: block;
            clear: both;
            margin: 25px auto 15px;
            width: 106px;
            height: 5px;
            border: 0;
            background: transparent url('../images/staff-thumb-diagonal.png') no-repeat center center;
        }

        button.txt-button {
            margin-bottom: 20px;
            width: auto;
            height: auto;
            background: transparent;
            color: $teal;
            font-weight: 600;
            font-size: 14px;
            line-height: 1em;
        }
    }

    .hover-panel {
        position: absolute;
        top: -100%;
        width: 100%;
        height: 100%;
        background: $bluechill;
        transition: top 0.35s ease-in-out;

        h4.name {
            margin-top: 133px;
            margin-bottom: 5px;
            color: #fff;
            text-align: center;
            font-size: 24px;
        }

        .title {
            margin: 5px 60px;
            margin-bottom: 60px;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            font-weight: 600;
        }

        .txt-button.view-info {
            position: absolute;
            bottom: 90px;
            left: 50%;
            font-weight: 700;
            font-size: 14px;
            transform: translateX(-50%);

            &:hover {
                background: transparent;
            }

            &:before {
                position: absolute;
                top: -35px;
                left: 50%;
                display: block;
                width: 36px;
                height: 36px;
                background: transparent url('../images/view-details.png') no-repeat center center;
                content: '';
                transform: translateX(-50%);
            }
        }
    }

    &:hover {
        .hover-panel {
            @media (min-width: 941px) {
                top: 0;
            }
        }
    }
}

.screen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.85);
}

.staff-bio-detail .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    padding: 35px 55px 0;
    width: 786px;
    background: rgba(9, 118, 128, 0.95) linear-gradient(to bottom, rgba(black, 0.25) 0%, rgba(130, 130, 130, 0) 51%, rgba(white, 0) 100%);
    color: #fff;
    transform: translateX(-50%) translateY(-50%);

    @media (max-width: 940px) {
        max-width: 90vw;
    }

    @media (max-width: 640px) {
        position: static;
        overflow-y: auto;
        padding-top: 90px;
        max-width: none;
        max-height: 100vh;
        width: 100vw;
        border: none;
        transform: none;
    }

    .close-btn {
        position: absolute;
        top: -25px;
        right: -25px;
        box-sizing: content-box;
        width: 48px;
        height: 48px;
        border: 2px solid #fff;
        border-radius: 50%;
        background: $surfiegreen;
        color: #fff;
        text-align: center;
        font-size: 24px;
        line-height: 50px;
        cursor: pointer;

        @media (max-width: 640px) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 55px;
            border: 0;
            border-radius: 0;
            background: $deepseagreen;
            line-height: 55px;
        }

        .mobile-text {
            display: none;
            vertical-align: top;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 18px;
            font-family: $proxima;

            @media (max-width: 640px) {
                display: inline;
            }
        }
    }

    .staffImg-container {
        position: absolute;
        top: -80px;
        left: -112px;
        overflow: hidden;
        width: 335px;
        height: 250px;
        box-shadow: 1px 1px 60px 10px rgba(black, 0.15);

        @media (max-width: 940px) {
            display: none;
        }

        img {
            position: absolute;
            top: 0;
            left: 50%;
            min-height: 100%;
            width: 100%;
            transform: translateX(-50%) translateY(0);
        }
    }

    .nametext,
    .titletext,
    .exptext {
        padding-left: 225px;

        @media (max-width: 940px) {
            padding-left: 0;
        }

        @media (max-width: 640px) {
            text-align: center;
        }
    }

    .nametext {
        margin-bottom: 6px;
        font-weight: 800;
        font-size: 24px;
    }

    .titletext {
        margin-bottom: 15px;
        text-transform: uppercase;
        white-space: nowrap;
        letter-spacing: 2px;
        font-weight: 600;
        font-size: 16px;

        @media (max-width: 940px) {
            white-space: pre-wrap;
        }
    }

    .exptext {
        font-weight: 500;
        font-style: italic;
        font-size: 16px;
        font-family: 'Lexia', serif;
    }

    .divider {
        position: relative;
        z-index: -1;
        margin: 38px -55px 55px 55px;
        width: 100%;
        height: 20px;
        background: transparent url('../images/staff-bio-divider.png') repeat-x;
        background-size: auto 100%;
        opacity: 0.25;

        @media (max-width: 940px) {
            margin: 30px 0 25px -55px;
            width: calc(100% + 110px);
            opacity: 1;
        }
    }

    .biotext {
        margin-bottom: 68px;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5em;

        @media (max-width: 640px) {
            text-align: center;
        }
    }

    .contact-details {
        display: flex;
        margin: 0 -55px;
        width: calc(100% + 110px);
        background: rgba(black, 0.1);

        flex-wrap: wrap;

        > div {
            display: flex;
            width: 50%;
            height: 57px;
            border: 2px solid $hippieblue2;
            border-right: 0;
            border-bottom: 0;
            line-height: 57px;

            justify-content: center;

            &:nth-child(odd) {
                border-left: 0;
            }

            @media (max-width: 640px) {
                width: 100%;
                border-right: 0;
                border-left: 0;

                &:last-child {
                    border-bottom: 2px solid $hippieblue2;
                }
            }

            .fa {
                margin-right: 10px;
                color: $neptune;
                font-size: 20px;
                line-height: 57px;

                &.fa-mobile {
                    font-size: 22px;
                }

                &.fa-print {
                    font-size: 18px;
                }

                &.fa-envelope {
                    font-size: 14px;
                }
            }

            label {
                margin-right: 5px;
                color: #fff;
            }

            span[class*='Text'] {
                font-weight: 400;
            }
        }
    }
}
