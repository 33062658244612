@mixin one-col {
  @media screen and (max-width: $medium) {
    margin-left: -25px;
  }

  .row-fill {
    display: none;
  }

  > li {
    width: 100vw;
    margin-right: 0;

    &:nth-child(5n) {
      width: 100vw;
      margin-right: 0;
    }
  }
}

@mixin two-col {
  @media screen and (max-width: $medium) {
    margin-left: -25px;
  }

  .row-fill {
    display: none;

    &:not(:nth-child(2n + 1)) {
      @media screen and (min-width: $medium) {
        display: block;
        border-bottom: 1px solid #ffffff;
        background: $concrete url('../images/striped-background.gif') repeat top left;
        flex-grow: 1;
      }
    }
  }

  > li {
    width: calc(50vw - 1px);

    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}

@mixin three-col {
  @media screen and (max-width: $medium) {
    margin-left: -25px;
  }

  .row-fill {
    display: none;

    &:not(:nth-child(3n + 1)) {
      @media screen and (min-width: $medium) {
        display: block;
        border-bottom: 1px solid #ffffff;
        background: $concrete url('../images/striped-background.gif') repeat top left;
        flex-grow: 1;
      }
    }
  }

  > li {
    width: calc(100vw / 3.02);

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:nth-child(5n) {
      width: calc(100vw / 3.02);
      margin-right: 1px;
    }
  }
}

@mixin four-col {
  @media screen and (max-width: $medium) {
    margin-left: -25px;
  }

  .row-fill {
    display: none;

    &:not(:nth-child(4n + 1)) {
      @media screen and (min-width: $medium) {
        display: block;
        border-bottom: 1px solid #ffffff;
        background: $concrete url('../images/striped-background.gif') repeat top left;
        flex-grow: 1;
      }
    }
  }

  > li {
    width: calc(25vw - 1px);

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}

.capabilities {
  h2.no-content {
    display: none;
  }

  //Default 5-column layout
  .main .ul1 {
    width: 100vw;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    .row-fill {
      display: none;

      &:not(:nth-child(5n + 1)) {
        @media screen and (min-width: $medium) {
          display: block;
          border-bottom: 1px solid #ffffff;
          background: $concrete url('../images/striped-background.gif') repeat top left;
          flex-grow: 1;
        }
      }

      &:after {
        content: none;
      }
    }

    &.one-col {
      @include one-col;
    }

    &.two-col {
      @include one-col;

      @media (min-width: $large) {
        @include two-col;
      }
    }

    &.three-col {
      @include one-col;

      @media (min-width: $medium) {
        @include three-col;
      }
    }

    &.four-col {
      @include one-col;

      @media (min-width: $medium) {
        @include four-col;
      }
    }

    &.five-col {
      @media (max-width: $large) {
        @include three-col;
        margin-left: -25px;
      }

      @media (max-width: $medium) {
        @include one-col;
      }
    }

    > li {
      position: relative;
      width: calc(20vw - 1px);
      height: 20vw;
      background: #666;
      overflow: hidden;
      margin: 0 1px 1px 0;
      padding: 0;

      &:after {
        position: absolute;
        width: 100%;
        content: '';
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.55);
        z-index: 1;
      }

      &:hover {
        cursor: pointer;

        &:after {
          background: rgba(0, 0, 0, 0.75);
        }
      }

      &:nth-child(5n) {
        margin-right: 0px;
        width: 20vw;
      }

      a {
        position: relative;
        height: 100%;
        display: block;
        transform: none;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 5;
        margin: 0 auto;
        border: 0;

        > span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 2;
          padding-bottom: 5px;
          border-bottom: 2px solid $yellowsea;
          color: #fff;
          text-transform: uppercase;
          letter-spacing: 1.5px;
          text-align: center;
          line-height: 1.5em;
          max-width: calc(100% - 40px);
          font-size: 14px;
          font-family: lexia-advertising;
        }

        &:hover {
          text-decoration: none;
        }
      }

      img {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        left: 50%;
        margin: 0;
        top: 50%;
        z-index: 0;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  .nav-text {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    padding-bottom: 5px;
    border-bottom: 2px solid $yellowsea;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1.25em;
    letter-spacing: 1.5px;
    font-size: 24px;
    font-family: $lexiaadvert;
    transform: translateX(-50%) translateY(-50%);
  }
}

//Capabilities Detail Page Layout/Styling
.capabilities-detail-container {
  position: relative;

  .page-container {
    padding-bottom: 90px;
    margin-bottom: 145px;
  }

  .capabilities-container img {
    display: none;
  }

  .row-fill {
    display: none;

    &:nth-child(-n+4) {
      @media screen and (min-width: $medium) {
        display: block;
        border-bottom: 1px solid #ffffff;
        background: $concrete url('../images/striped-background.gif') repeat top left;
        flex-grow: 1;
      }
    }
  }

  .image-navigation {
    height: 145px;
    position: absolute;
    left: 0;
    bottom: -145px;

    .image-nav-link {
      overflow: hidden;

      img {
        margin: 0;
      }
    }
  }
}