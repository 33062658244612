

// albums
.subpage .main .album {
  img {
    margin: 0;
  }

  a {
    display: block;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  // .col {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   overflow: hidden;

  //   img {
  //     flex-shrink: 0;
  //     min-width: 100%;
  //     min-height: 100%;
  //   }
  // }
}